<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column align-items-center justify-content-center"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div
          class="overlay-bg"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-15">
          <p v-if="authLoading">
            <v-skeleton-loader
              class="max-h-70px"
              type="image"
            ></v-skeleton-loader>
          </p>
          <p class="text-center mb-10" v-else>
            <a href="#" class="text-center">
              <img
                :src="app_logo_path"
                class="max-h-70px"
                alt="logo"
                v-if="app_logo_path != null"
              />
            </a>
          </p>

          <h3
            v-if="setting"
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            :style="taglineColor"
          >
            {{ setting.tagline || "" }}
          </h3>
          <h3
            v-if="setting"
            class="font-weight-bolder text-center font-size-h5"
            :style="sloganColor"
          >
            {{ setting.slogan || "" }}
          </h3>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL +
        "media/svg/illustrations/pexels-pixabay-261651.jpg"
      );
    }
  }
};
</script>

<style lang="scss">
.login-aside {
  position: relative;
  z-index: 1;
  background-size: cover;
  flex: 1.5 !important;

  & > * {
    position: relative;
    z-index: 3;
  }

  .overlay-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-color: var(--login-bg-color);
  }

  & + * {
    flex: 2 !important;
  }
}
</style>
